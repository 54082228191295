import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link,withRouter } from 'react-router-dom'
import { Layout, Menu } from 'antd';
import {
  DashboardOutlined,
  UserOutlined,
  DiffOutlined,
  LogoutOutlined,
  SettingFilled,
  InfoCircleOutlined,
  MailOutlined
} from '@ant-design/icons';
import cookie from 'react-cookies'

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


export class Body extends Component {
    state = {
      collapsed: false,
    };

    onCollapse = collapsed => {
      console.log(collapsed);
      this.setState({ collapsed });
    };

    handleLogout=()=>{
      cookie.remove('qtonixproject_admin_userdata', { path: '/' })
      cookie.remove('qtonixproject_admin_userlogin', { path: '/' })
      cookie.remove('qtonixproject_admin_userid', { path: '/' })
      // cookie.remove('qtonixproject_admin_useremailverify', { path: '/' })
  
     
      this.props.history.push(`/login/`, null);
  
    }

    render() {
      const { collapsed } = this.state;
        return (
            <>
              <Layout style={{ minHeight: '100vh' }}>
              <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                <div className="logo" />
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                  {cookie.load('qtonixproject_admin_userlogin') === 'true'
                  ?
                  <>
                  <Menu.Item key="1" icon={<DashboardOutlined />}>
                    <Link exact to='/'>Dashboard</Link>
                  </Menu.Item>
                  
                  <SubMenu key="sub1" icon={<DiffOutlined />} title="Domain Extract">
                    <Menu.Item key="3"><Link exact to="/emailextract">All</Link></Menu.Item>
                    <Menu.Item key="52x2"><Link exact to="/emailextract/quick">Quick Extract</Link></Menu.Item>

                    <Menu.Item key="7x2x2"><Link exact to="/emailextract/bulk">Bulk Extract</Link></Menu.Item>

                  </SubMenu>

                  <SubMenu key="sub23" icon={<MailOutlined />} title="Send Email">
                    <Menu.Item key="sub7"><Link exact to="/allsendemail">All</Link></Menu.Item>
                    <Menu.Item key="sub80"><Link exact to="/sendnewemail">Send Bulk Email</Link></Menu.Item>
                    
                    <SubMenu key="subt22" title="Email Drafts">
                      <Menu.Item key="subt221"><Link exact to="/emailtempletes">All</Link></Menu.Item>
                      <Menu.Item key="subt222"><Link exact to="/emailtempletes/Create">Add New</Link></Menu.Item>
                    </SubMenu>
                  </SubMenu>


                
                  

                  <SubMenu key="sub22" icon={<SettingFilled />} title="Settings">
                    <Menu.Item key="226"><Link exact to="/emailextract/conditions">Domain Filter</Link></Menu.Item>
                    <Menu.Item key="2269"><Link exact to="/emailfilter">Email Filter</Link></Menu.Item>
                    <Menu.Item key="228"><Link exact to="/emailsmtp">Email Accounts</Link></Menu.Item>

                    <SubMenu key="sub2" icon={<UserOutlined />} title="Users">
                      <Menu.Item key="sub27"><Link exact to="/users">All Users</Link></Menu.Item>
                    </SubMenu>

                    <Menu.Item key="227"><Link exact to="/loginips">IP Restrictions</Link></Menu.Item>

                    
                  </SubMenu>


                  <Menu.Item key="1xxsss" icon={<LogoutOutlined />}>
                    <span onClick={this.handleLogout}>Logout</span>
                  </Menu.Item>

                  <Menu.Item key="00100" icon={<InfoCircleOutlined />}>
                    <Link exact to='/version'>Version 1.02</Link>
                  </Menu.Item>
                  </>
                  :
                  <></>
                  }
                </Menu>
              </Sider>
              <Layout className="site-layout">
                {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}

                  <Header style={{ width: '100%' }}>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                      
                      <img src="/images/logowhite.png" alt="email-extractor-online" className="mylogo" />

                    </Menu>
                  </Header>
                
                <Content style={{ margin: '0 16px' }}>
                  {this.props.children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>Version 1.02</Footer>
              </Layout>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})


const BodyWithRouter = withRouter(Body);
export default connect(mapStateToProps)(BodyWithRouter);
