import React, { Component } from 'react'
import API from '../../api/API'
import { Loader } from 'semantic-ui-react'
import Moment from 'react-moment';

export default class SendemailViewDetails extends Component {

    state={
        pageLoading:true
    }

    componentDidMount(){
        API.get(`/sendemail/view/sendemail/${this.props.match.params.id}`)
        .then(response=>{
            if(response.data.response===true){
                this.setState({
                    pageLoading:false,
                    data:response.data.data
                })
            }else{

            }
            console.log(response.data)
        })
    }

    render() {
        return (
            <>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>View Send Email Details</Breadcrumb.Item>

            </Breadcrumb>*/}
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}> 
                {this.state.pageLoading
                ?<Loader active inline='centered' style={{marginTop:'8rem'}}/>
                :
                <>
                <h5>Date: <Moment format="YYYY-MM-DD dddd HH:MM:ss">{this.state.data.createdAt}</Moment></h5>
                                    <h5 style={{marginTop:'-8px'}}>From: {this.state.data.sendMailfrom}</h5>
                                    <h5 style={{marginTop:'-8px'}}>To: {this.state.data.sendMailto}  {this.state.data.status==='Success'?<span style={{color:'green'}}>Success</span>:<span style={{color:'red'}}>Failed</span>} </h5>
                                    <h4>Subject: {this.state.data.sendMailsubject}</h4>
                                    <br />
                                    <div dangerouslySetInnerHTML={{__html: this.state.data.sendMailhtml}}></div>
                </>}
            </div>  
            </>
        )
    }
}
