import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid,Loader,Button } from 'semantic-ui-react'
import API from '../../api/API'
import Moment from 'react-moment';
import { Breadcrumb, Statistic,Popconfirm, message } from 'antd';
import {Link} from 'react-router-dom'


export class UserView extends Component {

    constructor(props){
        super(props)
        this.state={
            pageLoading:true,
            fetchedDomainData:false,
            totaldomain:'',
            todaydomain:'',
            thismonthdomain:''
        }
    }


    componentDidMount(){
        API.get(`/user/viewuserdetails/${this.props.match.params.id}`)
        .then(response=>{
            console.log(response.data)
            if(response.data.response){
                this.setState({
                    pageLoading:false,
                    userinfo:response.data.data,
                    totaldomain:response.data.totaldomain,
                    todaydomain:response.data.todaydomain,
                    thismonthdomain:response.data.thismonthdomain,

                })
            }else{
                this.props.history.push('/users')
            }
        })
    }

    confirmDelete=e=>{
        API.get(`/user/delete/${e}`)
        .then(response=>{
            if(response.data.response){
                message.success('Success');
                this.props.history.push('/users')

            }else{
                message.warning('Failed');
            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
            <Breadcrumb.Item>View</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            
                <Grid>
                    {this.state.pageLoading
                    ?<Loader active inline='centered' />
                    :
                    <Grid.Column mobile={16} tablet={16} computer={10}>
                        <h3>UserInfo  

                        &nbsp;&nbsp;<Link exact to={`/users/edit/${this.state.userinfo._id}`}><Button content='Edit' size='mini' primary /></Link>
                        &nbsp;<Link exact to={`/users/changepassword/${this.state.userinfo._id}`}><Button content='Change Password' size='mini' primary /></Link>
                        &nbsp;
                        <Popconfirm
                            title={`Are you sure want to remove ${this.state.userinfo.name}?`}
                            onConfirm={()=>this.confirmDelete(this.state.userinfo._id)}
                            okText="Yes"
                            cancelText="No"
                        ><Button content='Delete' size='mini' color='red' /></Popconfirm>
                        
                        </h3>
                        <div>
                        <p><b>Name:</b> {this.state.userinfo.name}</p>
                        <p><b>Email:</b> {this.state.userinfo.email}</p>
                        <p><b>Contact:</b> {this.state.userinfo.contact}</p>
                        <p><b>Type:</b> {this.state.userinfo.usertype}</p>
                        <p><b>Status:</b> {this.state.userinfo.status}</p>

                        <p><b>Daily domain extract limit:</b> {this.state.userinfo.domainextractlimit}</p>
                        <p><b>Assignment email account:</b> {this.state.userinfo.assignemailaccount}</p>
                        <p><b>Daily email send limit:</b> {this.state.userinfo.dailyemailsendlimit}</p>


                        <p><b>Created on:</b> <Moment format="YYYY-MM-DD dddd  HH:mm:ss">{this.state.userinfo.createdAt}</Moment></p>
                   
                        </div>
                        
                    </Grid.Column>
                    }
                    <Grid.Column mobile={16} tablet={16} computer={2}>
                        <Statistic title="Total Domain" value={this.state.totaldomain} />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={2}>
                        <Statistic title="This Month" value={this.state.thismonthdomain}  />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={2}>
                        <Statistic title="Today" value={this.state.todaydomain} />
                    </Grid.Column>
                </Grid>
            
            </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView)
