import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import API from '../api/API'

export class BlockMyEmail extends Component {

    state={
        loader:true
    }

    componentDidMount(){
        API.get(`/blockemail/blockmyemail/${this.props.match.params.id}`)
        .then(response=>{
            this.setState({
                loader:false
            })
        })
    }


    render() {
        return (
            <>
                <br /><br /><br />
                {this.state.loader
                ?
                <center>
                <Loader active inline='centered' />
                <p>Please wait..</p>
                </center>
                :
                <center>
                <h2>You will never get emails from us.</h2>

                </center>
                }
                
                {/* {this.props.match.params.id} */}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockMyEmail)
