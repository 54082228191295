import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message } from 'antd';
import { Form } from 'semantic-ui-react'
import CKEditor from 'ckeditor4-react-advanced';
import API from '../../../api/API'

export class EmailtempleteCreate extends Component {

    constructor(props){
        super(props)
        this.state={
            formLoading:false,
        }
        this.handleTextChange=this.handleTextChange.bind(this)
        this.onEditorChange=this.onEditorChange.bind(this)
    }

    handleTextChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }


    onEditorChange( evt ) {
        this.setState({
            body: evt.editor.getData()
        });
    }


    handleSubmit=e=>{
        this.setState({formLoading:true})
        API.post('/emailtemplete',this.state)
        .then(response=>{
            console.log(response.data)
            if(response.data.response){
                this.setState({formLoading:false})
                message.success('Success');
                this.props.history.push('/emailtempletes');
            }else{
                this.setState({formLoading:false})
                message.success('Success');
            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Create Email Templetes</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                <Form.Input fluid label='Name' placeholder='Templete name' onChange={this.handleTextChange}
                        name='name'
                        value={this.state.name} required />
                <Form.TextArea label='Subject' placeholder='Templete subject' onChange={this.handleTextChange}
                        name='subject'
                        value={this.state.subject} required /> 
                <div className="required field">
                    <label>Body</label>
                    <CKEditor
                        onChange={this.onEditorChange}
                        config={{
                            width: '100%',
                            height: '150px',
                        }}
                    />
                </div>
                <Form.Button>Add</Form.Button>
                </Form>

            </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailtempleteCreate)
