import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader,Radio } from 'semantic-ui-react'
import { message } from 'antd'
import API from '../../api/API'
import {fetchAllUser} from '../../actions'


export class BlockUserIndexActive extends Component {

    constructor(props){
        super(props)
        this.state={
            user:props.data,
            isActive:props.data.status,
            loading:false
        }
    }

    handleChnage(e){
        

        if(e==='Active'){
            
            this.setState({
                isActive:'Inactive',
                loading:true
            })

            var data = {
                _id:this.state.user._id,
                status:'Inactive'
            }

            API.post('user/activeinactiveuser',data)
            .then(response=>{
                if(response.data.response){
                    this.setState({loading:false})
                    message.success('Success');
                    this.props.fetchAllUser();
                }else{
                    this.setState({loading:true})
                    message.warning('Failed');
                }
            })




        }else{
            
            this.setState({
                isActive:'Active',
                loading:true
            })

            var datasa = {
                _id:this.state.user._id,
                status:'Active'
            }

            API.post('user/activeinactiveuser',datasa)
            .then(response=>{
                if(response.data.response){
                    this.setState({loading:false})
                    message.success('Success');
                    this.props.fetchAllUser();
                }else{
                    this.setState({loading:true})
                    message.warning('Failed');
                }
            })
        }
    }


    render() {
        console.log(this.props)
        return (
            <>
              {this.state.loading
              ?<Loader active inline size='small' />
              :
              <Radio toggle checked={this.state.isActive==='Active'?true:false} onChange={()=>this.handleChnage(this.state.isActive)} />
            //   <Segment compact>
            //     <Radio toogle checked={this.state.isActive==='Active'?true:false} onChange={()=>this.handleChnage(this.state.isActive)} />
            //   </Segment>
              
              }
              
              
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})


export default connect(mapStateToProps, {fetchAllUser})(BlockUserIndexActive)

