import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,Popconfirm, message } from 'antd';
import {Loader,Grid,Button} from 'semantic-ui-react'
import API from '../../api/API'
import TableExtractCreate from './TableExtractCreate'
import Moment from 'react-moment';
import {fetchAllDomainData} from '../../actions'
import io from "socket.io-client";


export class EmailextractView extends Component {

    constructor(props){
        super(props)
        this.state={
            pageLoading:true,
            fetchedDomainData:false,
            data:{
                data:{
                    uuid:null
                }
            }
        }
    }

    componentDidMount(){



        
        var socket = io(process.env.REACT_APP_SOCKET, {     
            query: {
                view_email_extract_details_by_uuid: this.props.match.params.id,
            }              
        });
        socket.connect();
       

        console.log(socket)

        
        socket.on('view_email_extract_details_by_uuid',data=>{
            console.log(data)
            // this.setState({
            //     news:data
            // })
        })












        API.get(`/email/extdomaindetails/${this.props.match.params.id}`)
        .then(response=>{
            if(response.data.response){
                this.setState({
                    pageLoading:false,
                    data:response.data 
                })


                //GET DOMAIN LISTS
                API.get(`/email/indexuseruuid/${this.state.data.data.userid}/${this.state.data.data.uuid}`)
                .then(response2=>{
                    if(response2.data.data.length===0){

                    }else{
                        this.setState({
                            fetchedDomainData:response2.data.data
                        })
                    }   
                })


            }else{
                this.props.history.push('/emailextract')
            }
        })
    }


    confirmDelete=(uuid,id)=>{
        API.get(`/email/deleteextdomaindetails/${uuid}/${id}`)
        .then(response=>{
            if(response.data.response){
                this.props.fetchAllDomainData();

                message.success('Success');
                this.props.history.push('/emailextract')

            }else{
                message.warning('Failed');
            }
        })
    }



    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>All Email Extract</Breadcrumb.Item>
                <Breadcrumb.Item>View</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                {this.state.pageLoading
                ?<Loader active inline='centered' />
                :
                <>
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <h3>Extract Information 

                        &nbsp;
                        {this.state.data.data.uuid===null
                        ?<></>
                        :
                        <Popconfirm
                            title={`Are you sure want to delete this?`}
                            onConfirm={()=>this.confirmDelete(this.state.data.data.uuid,this.state.data.data._id)}
                            okText="Yes"
                            cancelText="No"
                        ><Button content='Delete' size='mini' color='red' /></Popconfirm>
                        }

                        
                    
                        </h3>
                        <p><b>Name: </b>{this.state.data.data.username}</p>
                        <p><b>Email: </b>{this.state.data.data.useremail}</p>
                        <p><b>UserType: </b>{this.state.data.data.usertype}</p>
                        <p><b>ID: </b>{this.state.data.data.uuid}</p>
                        <p><b>Total Domain: </b>{this.state.data.data.total}</p>
                        <p><b>Extracted Domain: </b>{this.state.data.totaldomain}</p>

                        <p><b>Date: </b><Moment format="YYYY-MM-DD dddd HH:mm:ss">{this.state.data.data.createdAt}</Moment></p>

                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    {this.state.fetchedDomainData===false
                    ?<Loader active inline='centered' />
                    :
                    <TableExtractCreate datas={this.state.fetchedDomainData} uuid={this.state.data.data.uuid} showExcelBtn={true}/>
                    }
                    </Grid.Column>
                </Grid>
                </>
                }
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})


export default connect(mapStateToProps, {fetchAllDomainData})(EmailextractView)
