import React, { Component } from 'react'
import { Breadcrumb,Popconfirm,message } from 'antd';
import { Loader,Button } from 'semantic-ui-react'
import API from '../../../api/API'
import { Link } from 'react-router-dom';


export default class EmailtempleteView extends Component {

    constructor(props){
        super(props)
        this.state={
            data:false
        }
    }

    componentDidMount(){
        API.get(`/emailtemplete/view/${this.props.match.params.id}`)
        .then(response=>{
            this.setState({
                data:response.data.data
            })
        })
    }


    handleDelete=e=>{
        API.get(`/emailtemplete/delete/${e}`)
        .then(response=>{
            if(response.data.response){
                message.success('Success');
                this.props.history.push('/emailtempletes')
            }else{
                message.warning('Failed')
            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>View Templetes</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {this.state.data===false
            ?<Loader active inline='centered' />
            :
            <>
            <h3>{this.state.data.name}
                
                <Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={()=>this.handleDelete(this.state.data._id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button color='red' floated='right'  size='mini'>Delete</Button>
                </Popconfirm>
                
                <Link exact to={`/emailtempletes/edit/${this.state.data._id}`}><Button primary floated="right"  size='mini'>Edit</Button></Link>


            </h3>
         
            <h4>{this.state.data.subject}</h4>
            <br />
            <div dangerouslySetInnerHTML={{__html: this.state.data.body}}></div>
            </>
            }
            </div>
            </>
        )
    }
}
