import API from '../api/API';

export const check = data => async dispatch => {
    dispatch({type:'CHECK_REDUX',payload:data})
}

//GET ALL USERS LIST
export const fetchAllUser = () => async dispatch => {
    const response = await API.get('/user');
    dispatch({type:'FETCH_ALL_USER', payload:response.data.data})
}

//GET ALL DOMAIN LIST
export const fetchAllDomainData = () => async dispatch => {
    const response = await API.get('/email/getdomainsofuser');
    dispatch({type:'FETCH_ALL_DOMAIN_DATA', payload:response.data.data})
}


//GET ALL SEND EMAIL LIST
export const fetchAllSendEmailData = () => async dispatch => {
    const response = await API.get('/sendemail');
    dispatch({type:'FETCH_ALL_SEND_EMAIL_DATA', payload:response.data.datas})
}



