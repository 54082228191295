import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form,Table } from 'semantic-ui-react'
import API from '../../../api/API'
import { message,Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';

export class BlockDomain extends Component {

    constructor(props){
        super(props)
        this.state={
            domain:'',
            domaintype:'Domain',
            formLoading:false,
            domainList:false
        }
        this.handleChange=this.handleChange.bind(this)
    }

    componentDidMount(){
        API.get('/blockdomain')
        .then(response=>{
            if(response.data.response){
                this.setState({domainList:response.data.data})
            }else{
            }
        })
    }

    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleDelete=e=>{

        API.get(`/blockdomain/deletedomain/${e}`)
        .then(response=>{
            if(response.data.response){
                this.setState({formLoading:false,domainList:response.data.data})
                message.success('Success');
            }else{
                this.setState({formLoading:false})
                message.warning('Failed');
            }
        })
    }

    handleSubmit=e=>{
        this.setState({formLoading:true})
        var dataTemp = {domain:this.state.domain,type:this.state.domaintype};
     
            console.log(dataTemp);
        API.post('/blockdomain',dataTemp)
        .then(response=>{
            if(response.data.response){
                this.setState({formLoading:false,domain:'',domainList:response.data.data})
                message.success('Success');
            }else{
                this.setState({formLoading:false})
                message.warning('Failed');
            }
        })
    }

    render() {
        return (
            <>
                <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                    <Form.Input label='Domain' placeholder='xyz.com' onChange={this.handleChange} value={this.state.domain} name="domain" required/>
                    <Form.Group inline>
                    <label>UserType</label>
                        <Form.Field
                            label='Domain (example.com)'
                            control='input'
                            type='radio'
                            name='domaintype'
                            onChange={this.handleChange}
                            value='Domain'
                            checked={this.state.domaintype==='Domain'}
                        />
                        <Form.Field
                            label='DomainWithoutSuffix (example)'
                            control='input'
                            type='radio'
                            name='domaintype'
                            onChange={this.handleChange}
                            value='DomainWithoutSuffix'
                            checked={this.state.domaintype==='DomainWithoutSuffix'}
                        />
                        <Form.Field
                            label='PublicSuffix (com)'
                            control='input'
                            type='radio'
                            name='domaintype'
                            onChange={this.handleChange}
                            value='PublicSuffix'
                            checked={this.state.domaintype==='PublicSuffix'}
                        />
                    </Form.Group>  
                    <Button>Add</Button>
                </Form>
                <br />
                {this.state.domainList===false
                ?
                <>No Domains Found</>
                :
                <Table stackable>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Domain</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.domainList.map((data)=>{
                            return(
                                <Table.Row>
                                    <Table.Cell>{data.type==='PublicSuffix'?<>.</>:<></>}{data.domain}</Table.Cell>
                                    <Table.Cell>{data.type}</Table.Cell>

                                    <Table.Cell textAlign='right'><Popconfirm
                                        title="Are you sure to delete this task?"
                                        onConfirm={()=>this.handleDelete(data._id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <span style={{color:'red'}}><DeleteOutlined /></span>
                                    </Popconfirm></Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                }
                
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockDomain)
