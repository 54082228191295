import React from 'react'
import { Button,  Modal } from 'semantic-ui-react'

function PreviewModal(props) {
  console.log(props)


  // var phrase = props.data.body;
  // var content = replaceString(phrase, '#domain#', 'mywebsite.com');
  // var content = phrase.replace('#domain#', 'mywebsite.com');

  
  if(props.data.databody){
    let re = /#domain#/gi;
    var myStr = props.data.databody;
    var newStr = myStr.replace(re, 'mywebsite.com');
  }else{
    // var newStr = '-';
  }

  


  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Preview Email</Button>}
    >
      <Modal.Header>Email Viewer</Modal.Header>
      <Modal.Content>
        <Modal.Description>

        <h5 style={{marginTop:'-8px'}}>From: {props.data.dataemailname} {props.data.dataemail} </h5>
        <h5 style={{marginTop:'-8px'}}>To: senduser@email.com  </h5>
        <h4>Subject: {props.data.datasubject}</h4>
        <br />
        <div dangerouslySetInnerHTML={{__html: newStr}}></div>
          

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default PreviewModal