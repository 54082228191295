import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,Popconfirm,message } from 'antd';
import {Loader,Grid,Table,Button} from 'semantic-ui-react'
import API from '../../api/API'
import Moment from 'react-moment';
import {fetchAllSendEmailData} from '../../actions'



export class SendmailView extends Component {

    constructor(props){
        super(props)
        this.state={
            pageLoading:true,
            tableLoading:true,
            sendinfo:null,
            tabledata:null
        }
    }


    componentDidMount(){

        API.get(`/sendemail/view/sendemailinfo/${this.props.match.params.id}`)
        .then(response=>{
            this.setState({
                pageLoading:false,
                sendinfo:response.data.data,
                total_success:response.data.totalsuccess,
                total_failed:response.data.totalfailed,
            })

            API.get(`/sendemail/view/sendemails/${this.state.sendinfo.uuid}`)
            .then(ress=>{
                this.setState({
                    tableLoading:false,
                    tabledata:ress.data.data
                })
            })

            
        })
    }


    confirmDelete=(uuid,id)=>{

        API.get(`/sendemail/delete/${uuid}/${id}`)
        .then(response=>{
            console.log(response.data)
            if(response.data.response){
                this.props.fetchAllSendEmailData();

                message.success('Success');
                this.props.history.push('/allsendemail')

            }else{
                message.warning('Failed');
            }
        })
    }


    // openInNewTab=e=>{
        
    //     return(
    //         <NewWindow>
    //             <h1>Hi 👋</h1>
    //         </NewWindow>
    //     )
    // }


    openInNewTab = (url) => {
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null

        window.open(url,'Data','height=650,width=950');
      }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>All Emails</Breadcrumb.Item>
                <Breadcrumb.Item>View</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                {this.state.pageLoading
                ?<Loader active inline='centered' />
                :
                <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <h3>Send Infromation

                        &nbsp;
                        {this.state.pageLoading
                        ?<></>
                        :
                        <Popconfirm
                            title={`Are you sure want to delete this?`}
                            onConfirm={()=>this.confirmDelete(this.state.sendinfo.uuid,this.state.sendinfo._id)}
                            okText="Yes"
                            cancelText="No"
                        ><Button content='Delete' size='mini' color='red' /></Popconfirm>
                        }

                        </h3>
                        <p><b>Name: </b>{this.state.sendinfo.username}</p>
                        <p><b>Email: </b>{this.state.sendinfo.useremail}</p>
                        <p><b>UserType: </b>{this.state.sendinfo.usertype}</p>
                        <p><b>Total Email: </b>{this.state.sendinfo.totalemail}</p>
                        <p><b>Total Success: </b><span style={{color:'green'}}>{this.state.total_success}</span></p>
                        <p><b>Total Failed: </b><span style={{color:'red'}}>{this.state.total_failed}</span></p>
                        <p><b>ID: </b>{this.state.sendinfo.uuid}</p>
                        <p><b>Date: </b><Moment format="YYYY-MM-DD dddd HH:mm:ss">{this.state.sendinfo.createdAt}</Moment></p>

                    </Grid.Column>
                    
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    {this.state.tableLoading
                    ?<Loader active inline='centered' />
                    :
                    <Table singleLine>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>From Email</Table.HeaderCell>
                            <Table.HeaderCell>To Email</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.tabledata.map((td)=>{
                                return(
                                    <Table.Row key={td._id}>
                                        <Table.Cell>
                                            {td.status==='Success'
                                            ?<span style={{color:'green'}}>Success</span>
                                            :<span></span>}

                                            {td.status==='Failed'
                                            ?<span style={{color:'red'}}>Failed</span>
                                            :<span></span>}


                                            {td.status==='Blocked email'
                                            ?<span style={{color:'red'}}>Blocked email</span>
                                            :<span></span>}


                                        </Table.Cell>
                                        <Table.Cell>{td.fromemail}</Table.Cell>
                                        <Table.Cell>{td.toemail}</Table.Cell>
                                        <Table.Cell><Moment format="YYYY-MM-DD dddd HH:mm:ss">{td.createdAt}</Moment></Table.Cell>
                                        <Table.Cell> 
                                            
                                            {/* <Link to={`/emailextract/viewfull/${td._id}`} target="_blank"><Button size='mini' primary>Show</Button></Link>  */}
                                            
                                            <Button size='mini' primary onClick={()=>this.openInNewTab(`/emailextract/viewfull/${td._id}`)}>View</Button>
                                            {/* <p onClick={() => this.openInNewTab('https://stackoverflow.com')}>text</p> */}

                                            </Table.Cell>
                                        
                                        
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                    }
                    </Grid.Column>

                    
                </Grid>
                }
            </div>
            </>
        )
    }
}





const mapStateToProps = (state) => ({
    
})


export default connect(mapStateToProps, {fetchAllSendEmailData})(SendmailView)
