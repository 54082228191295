import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message,Result } from 'antd';
import {Form,Button,Grid,Loader,Statistic} from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid';
import API from '../../api/API'
import cookie from 'react-cookies'
import TableExtractCreate from './TableExtractCreate'
import ReactSpeedometer from "react-d3-speedometer"

export class EmailextractCreate2 extends Component {

    constructor(props){
      super(props)
      this.state={
        maxvalue:3000,
        loadingUploadBtn:false,
        domains:'',
        domainCreate:null,
        userid:cookie.load('qtonixproject_admin_userid'),
        username:'',
        useremail:'',
        usertype:'',
        uuid:null,
        fetchedDomainData:null,
        showExcelBtn:false,
        formsubmitbutton:true,
        sendingLimitCheckingMessage:false,
        dailySendingExceededMessage:false,
        allProcessSuccess:false,
        runExtractor:false
      }
      this.handleChange=this.handleChange.bind(this)
    }


    componentDidMount(){
        //***USER INFO***
        var userinfo = cookie.load('qtonixproject_admin_userdata');

        //***GENERATE UUID***
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm +  dd + yyyy;
        this.setState({
            uuid:today+uuidv4(),
            username:userinfo.name,
            useremail:userinfo.email,
            usertype:userinfo.usertype,
        })

        this.timer = setInterval(() => {
            this.fetchData();
        }, 2000);

        

        API.post('/email/checkdailysendlimit',userinfo)
        .then(resps=>{
            console.log(resps.data)
            this.setState({
                showdailylimit:resps.data.dailylimit,
                showtodaysendemail:resps.data.todaysendemail,
                showtotalLeft:resps.data.totalLeft,

            })
        })

    }
    


    handleChange(e){
        this.setState({
          [e.target.name]:e.target.value
        })
    }


    //***SUBMIT DATA***
    handleSubmit=e=>{
        this.setState({loadingUploadBtn:true})
        const word = this.state.domains;
        const domains = word.split("\n");
        const state= this.state;
        var domainCreate = [];
        domains.forEach(domainFunction);
        function domainFunction(domainurl, index) {
            const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
            const myUrl = withHttp(domainurl);

            console.log(myUrl);

            if(myUrl==='http://'){

            }else{
                var data={
                domain:myUrl,
                uuid:state.uuid,
                userid:state.userid,
                username:state.username,
                useremail:state.useremail,
                usertype:state.usertype,
            }
            domainCreate.push(data);
            }

            
        }
        

        //CHECK LIMIT
        if(this.state.showtotalLeft<domainCreate.length){
     
            this.setState({
                loadingUploadBtn:false,
                dailySendingExceededMessage:true
            })
        
        }else{

            this.setState({
                // formsubmitbutton:true,
                dailySendingExceededMessage:false,
            })

                API.post('/email/parsedomainnew',domainCreate)
                .then(response=>{
                    if(response.data.response){

                        var singledata={
                            usertype:this.state.usertype,
                            userid:this.state.userid,
                            uuid:this.state.uuid,
                            username:state.username,
                            useremail:state.useremail,
                            total:domainCreate.length
                        }
                        API.post('/email/insertinfo/domainsingle',singledata)
                        .then(responseone=>{
                            console.log(responseone)
                        })

                        this.setState({
                            loadingUploadBtn:true,
                            domainCreate:domainCreate,
                            runExtractor:true
                        })
                        // this.startTimer();
                    }else{
                        message.warning('failed');
                        this.setState({
                            loadingUploadBtn:false
                        })
                    }
                })   
        }
    }


    //***FETCH DATA***
    fetchData(){
        
        
        if(this.state.runExtractor === false){

        }else{


                    API.get(`/email/indexuseruuid/${this.state.userid}/${this.state.uuid}`)
                
                    .then(response=>{
                        if(response.data.data.length===0){
        
                        }else{
                            this.setState({
                                fetchedDomainData:response.data.data
                            })

                            //stop fetcher
                            if(this.state.domainCreate.length===this.state.fetchedDomainData.length){
                                message.success('Success');
                                
                                this.setState({
                                    allProcessSuccess:true,
                                    runExtractor:false,
                                    showExcelBtn:true
                                })
                            }else{

                            }

                        }
                        
                    })
        
                    var userinfo = cookie.load('qtonixproject_admin_userdata');
        
                    API.post('/email/checkdailysendlimit',userinfo)
                    .then(resps=>{
                        console.log(resps.data)
                        this.setState({
                            showdailylimit:resps.data.dailylimit,
                            showtodaysendemail:resps.data.todaysendemail,
                            showtotalLeft:resps.data.totalLeft,
        
                        })
                    })
        }
    }



    render() {
        // console.log(this.state.fetchedDomainData)
        // console.log(this.state.domainCreate)
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>All Email Extract</Breadcrumb.Item>
                <Breadcrumb.Item>Quick Extract</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Grid>

                <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Statistic.Group  size='tiny'>
                        <Statistic horizontal label='Daily Limit' value={this.state.showdailylimit} />
                        <Statistic horizontal label='Extracted Today' value={this.state.showtodaysendemail} style={{marginLeft:'0px'}} />
                        <Statistic horizontal label='Left Today' value={this.state.showtotalLeft} />
                    </Statistic.Group>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={8} computer={10}>

                    {this.state.allProcessSuccess
                    ?
                    <Result
                        status="success"
                        title="Successfully Extracted"
                        subTitle={`Your extraction id is - ${this.state.uuid}`}
                    
                    />
                    :
                    <Form  onSubmit={this.handleSubmit} loading={this.state.loadingUploadBtn}>
                        <Form.TextArea
                            label='Domains'
                            name='domains'
                            onChange={this.handleChange}
                            value={this.state.domains}
                            placeholder=' abc.com
                            qwe.com
                            zyz.com ...'
                        />
                        


                        {this.state.formsubmitbutton
                        ?<Button type='submit' loading={this.state.loadingUploadBtn}>Upload</Button>
                        :<></>}
                                    
                        {this.state.sendingLimitCheckingMessage
                        ?<h4>Please wait, we're checking daily email sending limit...</h4>
                        :<></>}

                        {this.state.dailySendingExceededMessage
                        ?<h4 style={{color:'red'}}>You have exceeded the maximum upload limit. You can't upload more than {this.state.showtotalLeft}</h4>
                        :<></>}


                    </Form>
                    }
                    
                
                




                </Grid.Column>
                <Grid.Column mobile={16}  computer={6}>
                {this.state.domainCreate===null
                ?
                <></>
                :
                <>
                    {this.state.fetchedDomainData===null
                    ?
                    <>
                    <Loader active inline='centered' />
                    </>
                    :
                    <ReactSpeedometer
                        maxValue={this.state.domainCreate.length}
                        value={this.state.fetchedDomainData.length}
                        needleColor="black"
                        startColor="green"
                        segments={4}
                        endColor="blue"
                        textColor='black'
                    />
                    }
                    
                </>}
                </Grid.Column>
                </Grid>
                {this.state.domainCreate===null
                ?<></>
                :
                <>
                {this.state.fetchedDomainData===null
                    ?
                    <>
                    <Loader active inline='centered'  style={{marginTop:'160px'}}/>
                    </>
                    :
                    <>
                        <TableExtractCreate datas={this.state.fetchedDomainData} uuid={this.state.uuid} showExcelBtn={this.state.showExcelBtn}/>
                    </>
                }
                </>
                
                }
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EmailextractCreate2)
