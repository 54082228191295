import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from 'antd';
import { Button,Table,Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import API from '../../../api/API'
import Moment from 'react-moment';

export class EmailtempleteAll extends Component {

    state={
        datas:null
    }

    componentDidMount(){
        API.get('/emailtemplete')
        .then(response=>{
            this.setState({
                datas:response.data.data
            })
        })
    }

    render() {
        console.log(this.state)
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>All Email Templetes</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                
            
                {this.state.datas===null
                    ?<Loader active inline='centered' />
                    :
                    <Table singleLine>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Templete Name</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.datas.map((data)=>{
                                return(

                                        <Table.Row key={data._id}>
                                        <Table.Cell><b>{data.name}</b></Table.Cell>
                                        <Table.Cell><Moment format="YYYY-MM-DD dddd  HH:mm:ss">{data.createdAt}</Moment></Table.Cell>

                                        <Table.Cell textAlign='right'>
                                        
                                            <Link exact to={`/emailtempletes/view/${data._id}`}><Button primary floated="right" size="mini">View</Button></Link>
                
                                        </Table.Cell>
                                    </Table.Row>

                                )
                            })}
                            

                        </Table.Body>
                    </Table>
                }
            </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailtempleteAll)
