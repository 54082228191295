import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,Popconfirm,message } from 'antd';
import { Grid, Button, Form,Table } from 'semantic-ui-react'
import { DeleteOutlined } from '@ant-design/icons';
import API from '../api/API'


export class EmailFilter extends Component {

    constructor(props){
        super(props)
        this.state={
            email:'',
            formLoading:false,
            emailList:false
        }
        this.handleChange=this.handleChange.bind(this)
    }


    componentDidMount(){
        API.get('/blockemail')
        .then(response=>{
            if(response.data.response){
                this.setState({emailList:response.data.data})
            }else{
            }
        })
    }

    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleDelete=e=>{
        API.get(`/blockemail/deleteemail/${e}`)
        .then(response=>{
            if(response.data.response){
                this.setState({formLoading:false,emailList:response.data.data})
                message.success('Success');
            }else{
                this.setState({formLoading:false})
                message.warning('Failed');
            }
        })
    }

    handleSubmit=e=>{
        this.setState({formLoading:true})
        var dataTemp = {email:this.state.email};
        // console.log(dataTemp);
        API.post('/blockemail',dataTemp)
        .then(response=>{
            if(response.data.response){
                this.setState({formLoading:false,email:'',emailList:response.data.data})
                message.success('Success');
            }else{
                this.setState({formLoading:false})
                message.warning('Failed');
            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Blocked Emails</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                {/* <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur at tempore vitae natus excepturi sint assumenda nemo, doloribus, enim ipsum, recusandae libero quibusdam mollitia impedit id qui adipisci placeat corporis?</p>
                    </Grid.Column>
                </Grid> */}

                <Grid stackable columns={1}>
                    <Grid.Row>
                    <Grid.Column>
                      


                    <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                        <Form.Input label='Email' placeholder='text@email.com' onChange={this.handleChange} value={this.state.email} name="email" required/>
                         
                        <Button>Add</Button>
                    </Form>
                    <br />
                    {this.state.emailList===false
                    ?
                    <>No Domains Found</>
                    :
                    <Table stackable>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Domain</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.emailList.map((data)=>{
                                return(
                                    <Table.Row>
                                        <Table.Cell>{data.email}</Table.Cell>

                                        <Table.Cell textAlign='right'><Popconfirm
                                            title="Are you sure to delete this task?"
                                            onConfirm={()=>this.handleDelete(data._id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <span style={{color:'red'}}><DeleteOutlined /></span>
                                        </Popconfirm></Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                    }


                    </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>  
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailFilter)

