import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from 'antd';
import {fetchAllUser} from '../../actions'
import { Table,Loader,Button } from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import BlockUserIndexActive from './BlockUserIndexActive'
import {PlusOutlined} from '@ant-design/icons';

export class UserIndex extends Component {

    componentDidMount(){
        this.props.fetchAllUser();
    }

    render() {
        // console.log(this.props.users)
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Users</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                {this.props.users===undefined
                    ?<Loader active inline='centered' />
                    :
                    <>
                    <Link exact to='/users/create'><Button primary><PlusOutlined /> Create</Button></Link>
                    <Table singleLine>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Contact</Table.HeaderCell>
                            <Table.HeaderCell>UserType</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.props.users.map((user)=>{
                                return(

                                    <Table.Row key={user._id}>
                                        <Table.Cell>{user.name}</Table.Cell>
                                        <Table.Cell>{user.email}</Table.Cell>
                                        <Table.Cell>{user.contact}</Table.Cell>
                                        <Table.Cell>{user.usertype}</Table.Cell>
                                        <Table.Cell>
                                            
                                            <BlockUserIndexActive data={user} />
                                            
                                        
                                        </Table.Cell>
                                        <Table.Cell textAlign='right'>
                                            <Link exact to={`/users/view/${user._id}`}><Button content='Manage' size='mini' primary /></Link>
                                            
                                        </Table.Cell>
                                    </Table.Row>

                                )
                            })}
                            

                        </Table.Body>
                    </Table>
                    </>
                }
            </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    users:state.users  
})


export default connect(mapStateToProps, {fetchAllUser})(UserIndex)
