import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid,Form,Input,Button,Loader} from 'semantic-ui-react'
import { Breadcrumb,message } from 'antd';

import ReactFormInputValidation from "react-form-input-validation";
import {FFSErrorMessage} from '../../styles/Styles'
import API from '../../api/API'

export class UserChangepassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
          buttonLoader:false,
          pageLoading:true,
          fields: {
            password: "",
          },
          errors: {}
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            password: "required|min:8|max:40",

        });
        this.form.onformsubmit = (fields) => {

          this.setState({buttonLoader:true})


          API.post('/user/update/password',fields)
          .then(response=>{
              console.log(response.data)
              if(response.data.response){
                this.setState({buttonLoader:false})
                message.success('Success');
                this.props.history.push('/users')
              }else{
                message.error('Try again');
                this.setState({buttonLoader:false})
              }
          })

        }
    }

    componentDidMount(){
        // alert(this.props.match.params.id)
        API.get(`/user/viewuserdetails/${this.props.match.params.id}`)
        .then(response=>{
            console.log(response.data)
            if(response.data.response){
                this.setState({
                    pageLoading:false,
                    fields:{
                    _id:response.data.data._id,
                    }
                    
                })
            }else{
                this.props.history.push('/users')
            }
        })
    }


    render() {
        return (
            <>
               <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Users</Breadcrumb.Item>
                <Breadcrumb.Item>Change Password</Breadcrumb.Item>


            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {this.state.pageLoading
            ?<Loader active inline='centered' />
            :
            <>
            <Grid>
                <Grid.Column mobile={16} computer={5}></Grid.Column>
                <Grid.Column mobile={16} computer={6}>
                    <Form onSubmit={this.form.handleSubmit} loading={this.state.buttonLoader?true:false}>
                    <h1>Change Password User</h1>
                    
                    <Form.Field
                        control={Input}
                        type='password'
                        label='New Password'
                        name='password'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.password} 
                        placeholder="New Password"
                    />
                    <FFSErrorMessage>{this.state.errors.password ? this.state.errors.password : ""}</FFSErrorMessage>

                   

                    <Button type='submit'  loading={this.state.buttonLoader?true:false}>Update</Button>
                    </Form>
                </Grid.Column>
                <Grid.Column mobile={16} computer={6}></Grid.Column>
                </Grid>
            </>
            }
                

                </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(UserChangepassword)
