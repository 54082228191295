import {combineReducers} from 'redux'

import checkRedux from './checkRedux'
import userReducer from './userReducer';
import domainDataReducer from './domainDataReducer'
import sendEmailDataReducer from './sendEmailDataReducer'

const songReducer = () => {
    return[
        {title:'Song1', duration:'4.45'},
        {title:'Song2', duration:'5.45'},
        {title:'Song3', duration:'6.45'},
        {title:'Song4', duration:'7.45'},
        {title:'Song5', duration:'8.45'},
    ]
};

export default combineReducers({
    songs:songReducer,
    users:userReducer,
    domaindatas:domainDataReducer,
    sendemaildatas:sendEmailDataReducer,
    check:checkRedux
})