import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message,Result } from 'antd';
import { Grid,Form,TextArea,Input,Button,Table, Statistic } from 'semantic-ui-react'
import CKEditor from 'ckeditor4-react-advanced';
import API from '../../api/API'
import {ExcelRenderer} from 'react-excel-renderer';
import cookie from 'react-cookies'
import { v4 as uuidv4 } from 'uuid';
import ReactSpeedometer from "react-d3-speedometer"
import PreviewModal from './PreviewModal'

export class SendemailNew extends Component {

    constructor(props){
        super(props)
        this.state={
            listsendemails:null,
            excellength:false,
            formLoading:false,
            fromemails:null,
            emailtempletes:null,
            mailsendboxhidden:true,
            dataemail:'loading...',
            dataemailname:'loading...',
            datatemplete:false,
            datasubject:false,
            databody:false,
            dataexcel:false,
            datauuid:false,
            datausername:false,
            datauseremail:false,
            datausertype:false,
            dataschedule:'No',
            formsubmitbutton:true,
            sendingLimitCheckingMessage:false,
            dailySendingExceededMessage:false,
            showdailylimit:'-',
            showtodaysendemail:'-',
            showtotalLeft:'-',
            runExtractor:false,
            allProcessSuccess:false,
            
        }
        this.handleChange=this.handleChange.bind(this);
        this.onEditorChange=this.onEditorChange.bind(this);
        this.handleDateChange=this.handleDateChange.bind(this);
    }

    componentDidMount(){

        var userinfo = cookie.load('qtonixproject_admin_userdata');

        //***GENERATE UUID***
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm +  dd + yyyy;
        this.setState({
            datauuid:today+uuidv4(),
            datausername:userinfo.name,
            datauseremail:userinfo.email,
            datausertype:userinfo.usertype,
        })


        API.get(`/user/viewuserdetails/${userinfo._id}`)
        .then(resu=>{
            // console.log(resu.data.data.assignemailaccount)

                API.get(`/emailsmtp/viewdatabyemail/${resu.data.data.assignemailaccount}`)
                .then(rese=>{
                    console.log(rese.data.data)

                    this.setState({
                        dataemail:rese.data.data.email,
                        dataemailname:rese.data.data.name
                    })
                })


        })


        API.post('/sendemail/checkdailysendlimit',userinfo)
        .then(resps=>{
            console.log(resps.data)
            this.setState({
                showdailylimit:resps.data.dailylimit,
                showtodaysendemail:resps.data.todaysendemail,
                showtotalLeft:resps.data.totalLeft,

            })
        })


        API.get('/emailtemplete')
        .then(response=>{
            this.setState({
                emailtempletes:response.data.data
            })
        })


        this.timer = setInterval(() => {
            this.fetchData();
        }, 7000);


    }


    //***FETCH DATA***
    fetchData(){


        if(this.state.runExtractor === false){


        }else{

            var tempD={
                email:this.state.datauseremail
            }
    
            //STOP AFTER TOTAL SEND
            API.get(`/sendemail/view/sendemails/${this.state.datauuid}`)
            .then(responsesw=>{
                console.log(responsesw.data)
                    if(responsesw.data.data.length===0){
                    }else{
                        this.setState({
                            listsendemails:responsesw.data.data
                        })

                
                        //stop fetcher
                        if(this.state.excellength===this.state.listsendemails.length){
                            message.success('Success');
                            
                            this.setState({
                                allProcessSuccess:true,
                                runExtractor:false,
                            })
                        }else{

                        }



                    }
            })
           
    
            API.post('/sendemail/checkdailysendlimit',tempD)
            .then(resps=>{
                this.setState({
                    showdailylimit:resps.data.dailylimit,
                    showtodaysendemail:resps.data.todaysendemail,
                    showtotalLeft:resps.data.totalLeft,
    
                })
            })

        }

        
    }



    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }


    handleChangeEmail=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
        API.get(`/emailsmtp/viewdatabyemail/${e.target.value}`)
        .then(response=>{
            this.setState({
                dataemailname:response.data.data.name,
            })
        })
    }

    onEditorChange( evt ) {
        this.setState({
            databody: evt.editor.getData()
        });
    }

    //**EXCEL UPLOAD***
    fileHandler = (event) => {

        this.setState({
            formsubmitbutton:false,
        })


        let fileObj = event.target.files[0];
    
        ExcelRenderer(fileObj, (err, resp) => {
            
            if(this.state.showtotalLeft<resp.rows.length){

                this.setState({
                    dailySendingExceededMessage:true
                })
            
            }else{

                if(resp.rows.length>500){
                    message.warning("You can't send more than 500 emails at a time.")
                }else{
                    this.setState({
                        formsubmitbutton:true,
                        dailySendingExceededMessage:false,
                        dataexcel:resp.rows
                    })
                }

                
            }

            
        });               
    
      }

    handleTempleteChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value,
        })

        API.get(`/emailtemplete/viewbyname/${e.target.value}`)
        .then(response=>{
            this.setState({
                mailsendboxhidden:false,
                datasubject:response.data.data.subject,
                databody:response.data.data.body,
            })
        })
    }


    handleFormSubmit=e=>{
        this.setState({formLoading:true,runExtractor:true})

        var data= {
            fromemail:this.state.dataemail,
            dataemailname:this.state.dataemailname,
            templete:this.state.datatemplete,
            subject:this.state.datasubject,
            body:this.state.databody,
            excel:this.state.dataexcel,
            uuid:this.state.datauuid,
            username:this.state.datausername,
            useremail:this.state.datauseremail,
            usertype:this.state.datausertype,

            date:this.state.dataday,
            month:this.state.datamonth,
            year:this.state.datayear,
            hour:this.state.datahour,
            minute:this.state.dataminute,
            schedule:this.state.dataschedule,

            status:this.state.dataschedule==='Yes'?'Pending':'Success',
            
            totalemail:this.state.dataexcel.length
        }


        API.post('/sendemail/newstorewithschedule',data)
        .then(response=>{
            // console.log(response.data)
            if(response.data.response){
                message.success('success');
                this.setState({
                    excellength:this.state.dataexcel.length,
                    
                })
            }else{
                message.warning('Failed');

            }

        })

    }


    handleDateChange(e){
        // console.log(e.target.value)

        var date = new Date(e.target.value);
        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();
        var hour = date.getHours();
        var minute = date.getMinutes();


        this.setState({
            dataday:day,
            datamonth:month,
            datayear:year,
            datahour:hour,
            dataminute:minute,
        })

        // console.log(day+'-'+month+'-'+year+'-'+hour+'-'+minute)


    }


    

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Send New Emails</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 1360 }}>
                <Grid style={{marginBottom:'-114px'}}>
                    
                    <Grid.Column mobile={16} tablet={8} computer={9}>

                
                        <Statistic.Group  size='tiny'>
                            <Statistic horizontal label='Daily Limit' value={this.state.showdailylimit} />
                            <Statistic horizontal label='Send Today' value={this.state.showtodaysendemail} style={{marginLeft:'0px'}} />
                            <Statistic horizontal label='Left Today' value={this.state.showtotalLeft} />
                        </Statistic.Group>
                    </Grid.Column>
                        

                    <Grid.Column mobile={16} tablet={8} computer={9}>

                        {this.state.allProcessSuccess
                        ?
                        <Result
                            status="success"
                            title="Successfully send"
                            subTitle={`Your id is - ${this.state.datauuid}`}
                        
                        />
                        :
                        <>
                        <Form onSubmit={this.handleFormSubmit} loading={this.state.formLoading}>
                            {/* <Form onSubmit={this.handleFormSubmit}> */}

                                <Form.Field
                                    control={Input}
                                    label='Email'
                                    placeholder='From email'
                                    required 
                                    name="dataemail"
                                    // onChange={this.handleChange}
                                    value={this.state.dataemail}
                                    // disabled={this.state.dataemail===false?true:false}
                                />


                                <Form.Field
                                    control={Input}
                                    label='Email name'
                                    placeholder='From email name'
                                    required 
                                    name="dataemailname"
                                    onChange={this.handleChange}
                                    value={this.state.dataemailname}
                                    disabled={this.state.dataemail===false?true:false}
                                />

                               

                                

                                



                                <Form.Field label='Templete' control='select' required name="datatemplete" value={this.state.datatemplete} onChange={this.handleTempleteChange} disabled={this.state.dataemail===false?true:false}>
                                    {this.state.emailtempletes===null
                                    ?
                                    <option value="">loading...</option>
                                    :
                                    <>
                                        {this.state.datatemplete===false
                                        ?<option value="">Select Templete</option>
                                        :<></>}
                                        {this.state.emailtempletes.map((fe)=>{
                                            return(
                                                <option value={fe.name} key={fe._id}>{fe.name}</option>
                                            )
                                        })}
                                    </>
                                    }
                                </Form.Field>



                                <div hidden={this.state.mailsendboxhidden}>
                                {/* <div> */}

                                    <Form.Field
                                        control={TextArea}
                                        label='Subject'
                                        placeholder='Opinion'
                                        required
                                        name="datasubject" value={this.state.datasubject} onChange={this.handleChange}
                                    />
                                    
                                    <div className="required field">
                                        <label>Body</label>
                                        <CKEditor
                                            onChange={this.onEditorChange}
                                            config={{
                                                width: '100%',
                                                height: '150px',
                                            }}
                                            data={this.state.databody}
                                        />
                                    </div>

                                    <Form.Field
                                        control={Input}
                                        type='file'
                                        label='Uplaod excel'
                                        onChange={this.fileHandler.bind(this)}
                                        required

                                    />


                                    <Form.Group grouped>
                                    <label>Schedule Send</label>
                                    <Form.Field
                                        label='Yes'
                                        control='input'
                                        type='radio'
                                        name='dataschedule'
                                        value="Yes"
                                        checked={this.state.dataschedule==='Yes'?true:false}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Field
                                        label='No'
                                        control='input'
                                        type='radio'
                                        name='dataschedule'
                                        value="No"
                                        checked={this.state.dataschedule==='No'?true:false}
                                        onChange={this.handleChange}

                                    />
                                    </Form.Group>

                                    {this.state.dataschedule==='Yes'
                                    ?
                                    <div className="field">
                                        <label>Schedule Date and Time</label>
                                        <div className="ui input">
                                            <input name="date" required type="datetime-local" onChange={this.handleDateChange} />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                    }

                                    


                                    
                                    {this.state.formsubmitbutton
                                    ?
                                    <>
                                    <Form.Field control={Button}>Send</Form.Field>
                                    
                                   


                                    </>
                                    :<></>}
                                    
                                    {this.state.sendingLimitCheckingMessage
                                    ?<h4>Please wait, we're checking daily email sending limit...</h4>
                                    :<></>}

                                    {this.state.dailySendingExceededMessage
                                    ?<h4 style={{color:'red'}}>You have exceeded the maximum upload limit. You can't upload more than {this.state.showtotalLeft}</h4>
                                    :<></>}

                                </div>
                            </Form>


                            <br />
                            <div hidden={this.state.mailsendboxhidden}>
                                {/* MODAL */}
                                    <PreviewModal data={this.state} />
                                {/* MODAL */}
                            </div>
                            
                            </>


                            }

                        



                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={7}>
                        
                        {this.state.excellength===false
                        ?<></>
                        :
                        <center>
                        <ReactSpeedometer
                            maxValue={this.state.excellength}
                            value={this.state.listsendemails===null?0:this.state.listsendemails.length}
                            needleColor="black"
                            startColor="green"
                            segments={4}
                            endColor="blue"
                            textColor='black'
                        />
                        </center>
                        }

                        {this.state.listsendemails===null
                        ?<></>
                        :
                        <>
                        <Table singleLine style={{marginTop:'-7rem'}}>
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.state.listsendemails.map((ems)=>{
                                    return(
                                        <Table.Row key={ems._id}>
                                            <Table.Cell>{ems.toemail}</Table.Cell>
                                            <Table.Cell> 
                                                {ems.status==='Success'
                                                ?<span style={{color:'green'}}>Success</span>
                                                :<span style={{color:'red'}}>Failed</span>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                

                            </Table.Body>
                        </Table>
                        <br /> <br /> <br /> <br /> <br />
                        </>
                        }
                        
                        

                    </Grid.Column>
                </Grid>
            </div>  
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SendemailNew)
