import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Table, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

// import { Table,Loader,Button,Statistic } from 'semantic-ui-react'
import { Loader,Button,Statistic } from 'semantic-ui-react'

import {fetchAllDomainData} from '../../actions'
import Moment from 'react-moment';
import {Link} from 'react-router-dom'
import cookie from 'react-cookies'
import API from '../../api/API'

// import io from "socket.io-client";


export class EmailextractIndex extends Component {

    constructor(props){
        super(props)
        this.state={
            showdailylimit:'-',
            showtodaysendemail:'-',
            showtotalLeft:'-',
            // domaindatas:undefined
        }
    }

    componentDidMount(){


        // var socket = io(process.env.REACT_APP_SOCKET);
        // socket.connect();

        // socket.on('all_domain_info',data=>{
        //     console.log(data)
        //     this.setState({
        //         domaindatas:data
        //     })
        // })

        this.props.fetchAllDomainData();

        var userinfo = cookie.load('qtonixproject_admin_userdata');


        API.post('/email/checkdailysendlimit',userinfo)
        .then(resps=>{
            console.log(resps.data)
            this.setState({
                showdailylimit:resps.data.dailylimit,
                showtodaysendemail:resps.data.todaysendemail,
                showtotalLeft:resps.data.totalLeft,
            })
        })
    }



    //TABLE FILTER//
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      //TABLE FILTER//





    render() {

        
        const columns = [
            {
              title: 'Name',
              dataIndex: 'username',
              key: 'username',
              width: 150,
              ...this.getColumnSearchProps('username'),
        
            },
            {
                title: 'Email',
                dataIndex: 'useremail',
                key: 'useremail',
                // render: mainprice => <span>₹ {mainprice} </span>,
                width: 200,
                ...this.getColumnSearchProps('useremail'),
    
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                // render: mainprice => <span>₹ {mainprice} </span>,
                width: 80,
                ...this.getColumnSearchProps('total'),
    
            },
            {
                title: 'ID',
                dataIndex: 'uuid',
                key: 'uuid',
                // render: mainprice => <span>₹ {mainprice} </span>,
                width: 200,
                ...this.getColumnSearchProps('uuid'),
    
            },
            {
                title: 'Created',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 200,
                render: createdAt => <Moment format="YYYY-MM-DD dddd  HH:mm:ss">{createdAt}</Moment>,
                // ...this.getColumnSearchProps('createdAt')

            },
            {
                title: 'Action',
                key: 'action',
                width: 70,
          
                render: (text, record) => (
                    <Link exact to={`/emailextract/view/${record._id}`}><Button content='View' size='mini' primary /></Link>
                ),
              },
          ];


        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>All Email Extract</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {this.props.domaindatas===undefined
                    ?<Loader active inline='centered' />
                    :
                    <>
                    <Statistic.Group  size='tiny'>
                        <Statistic horizontal label='Total' value={this.props.domaindatas.length} />
                        <Statistic horizontal label='Daily Limit' value={this.state.showdailylimit}  style={{marginLeft:'0px'}}/>
                        <Statistic horizontal label='Extracted Today' value={this.state.showtodaysendemail} />
                        <Statistic horizontal label='Left Today' value={this.state.showtotalLeft} />
                    </Statistic.Group>
                    <br />


                    <Table columns={columns} dataSource={this.props.domaindatas} pagination={{ pageSize: 10 }} scroll={{ y: 900, }} />


                    {/* <Table stackable>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>UserType</Table.HeaderCell>
                            <Table.HeaderCell>TotalDomain</Table.HeaderCell>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.props.domaindatas.map((data)=>{
                                return(
                                    <Table.Row>
                                        <Table.Cell>{data.username}</Table.Cell>
                                        <Table.Cell>{data.useremail}</Table.Cell>
                                        <Table.Cell>{data.total}</Table.Cell>
                                        <Table.Cell>{data.uuid}</Table.Cell>
                                        <Table.Cell><Moment format="YYYY-MM-DD dddd  HH:mm:ss">{data.createdAt}</Moment></Table.Cell>
                                        <Table.Cell textAlign='right'><Link exact to={`/emailextract/view/${data._id}`}><Button content='View' size='mini' primary /></Link></Table.Cell>
                                    </Table.Row>
                                )
                            })}
                            
                        </Table.Body>
                    </Table> */}
                    </>
                    }
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    domaindatas:state.domaindatas
})


export default connect(mapStateToProps, {fetchAllDomainData})(EmailextractIndex)