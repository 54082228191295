import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message,Popconfirm } from 'antd';
import { Form, Input, Button, Table,Loader,Radio } from 'semantic-ui-react'
import API from '../api/API'
import Moment from 'react-moment';

export class Loginip extends Component {

    constructor(props){
        super(props)
        this.state={
            datas:null,
            formLoading:false,
            ip:''
        }
        this.handleChange=this.handleChange.bind(this);
    }

    componentDidMount(){
        // axios.get('https://api.ipify.org?format=json')
        // .then(response=>{
        //     console.log(response.data.ip)
        // })
        this.getData();
    }


    getData=e=>{
        API.get('/loginip')
        .then(response=>{
            console.log(response)
            this.setState({
                datas:response.data.data
            })
        })
    }

    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit=e=>{
        this.setState({formLoading:true})
        API.post('/loginip',this.state)
        .then(response=>{
            if(response.data.response){
                message.success('Added');
                this.setState({formLoading:false,ip:''})
                this.getData();
            }else{
                message.warning('Failed');
            }
        })
    }

    handleDelete=e=>{
        API.get(`/loginip/delete/${e}`)
        .then(response=>{
            if(response.data.response){
                message.success('Success');
                this.getData();
            }else{
                message.warning('Failed');

            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Login Ip</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                    <Form.Field
                        control={Input}
                        label='IP'
                        placeholder='Whitelist IP'
                        required
                        onChange={this.handleChange}
                        name='ip'
                        value={this.state.ip}
                    />
                    <Form.Field control={Button}>Add</Form.Field>
                </Form>
                <br />
                {this.state.datas===null
                    ?<Loader active inline='centered' />
                    :
                    <Table singleLine>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>IP</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.datas.map((data)=>{
                                return(

                                        <Table.Row key={data._id}>
                                        <Table.Cell><b>{data.ip}</b></Table.Cell>
                                        <Table.Cell><Moment format="YYYY-MM-DD dddd  HH:MM:ss">{data.createdAt}</Moment></Table.Cell>

                                        <Table.Cell>
                                            
                                            <IpDeleteBlock data={data} />
                                            
                                        
                                        </Table.Cell>
                                        <Table.Cell textAlign='right'>
                                        <Popconfirm
                                            title="Are you sure to delete this IP?"
                                            onConfirm={()=>this.handleDelete(data._id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button content='Delete' size='mini' color='red' />
                                        </Popconfirm>
                                           
                
                                        </Table.Cell>
                                    </Table.Row>

                                )
                            })}
                            

                        </Table.Body>
                    </Table>
                }
            </div>
            </>
        )
    }
}


// FOR ACTIVE INACTIVE

export class IpDeleteBlock extends Component {

    constructor(props){
        super(props)
        this.state={
            data:props.data,
            isActive:props.data.status,
            loading:false
        }
    }

    componentDidMount(){
        console.log(this.props.data)
    }
    handleChnage(e){
        
        if(e==='Active'){
            this.setState({
                isActive:'Inactive',
                loading:true
            })
            var data = {
                _id:this.state.data._id,
                status:'Inactive'
            }
            API.post('loginip/activeinactive',data)
            .then(response=>{
                if(response.data.response){
                    this.setState({loading:false})
                    message.success('Success');

                }else{
                    this.setState({loading:true})
                    message.warning('Failed');
                }
            })


        }else{
            
            this.setState({
                isActive:'Active',
                loading:true
            })

            var datasa = {
                _id:this.state.data._id,
                status:'Active'
            }

            API.post('loginip/activeinactive',datasa)
            .then(response=>{
                if(response.data.response){
                    this.setState({loading:false})
                    message.success('Success');

                }else{
                    this.setState({loading:true})
                    message.warning('Failed');
                }
            })
        }
    }

    render() {
        return (
            <>
             
             {this.state.loading
              ?<Loader active inline size='small' />
              :
              <Radio toggle checked={this.state.isActive==='Active'?true:false} 
              
              onChange={()=>this.handleChnage(this.state.isActive)} 
              
              />
            
              
              }

            </>
        )
    }
}



const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Loginip)
