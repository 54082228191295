import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid,Form,Input,Button} from 'semantic-ui-react'
import { Breadcrumb,message } from 'antd';
import ReactFormInputValidation from "react-form-input-validation";
import {FFSErrorMessage} from '../styles/Styles'
import API from '../api/API'

export class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
          buttonLoader:false,
          fields: {
            email: "",
            password: "",
          },
          errors: {}
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            email: "required|email",
            password: "required|min:8|max:40",
            name: "required|min:2|max:90",
            contact: "required|numeric|digits_between:10,12"

        });
        this.form.onformsubmit = (fields) => {
          this.setState({buttonLoader:true})

          API.post('/user',fields)
          .then(response=>{
              console.log(response.data)
              if(response.data.response){
                this.setState({buttonLoader:false})
                message.success('Success');
              }else{
                message.error('Email aready exist.');
                this.setState({buttonLoader:false})
              }
          })

        }
      }


    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Register</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Grid>
                <Grid.Column mobile={16} computer={5}></Grid.Column>
                <Grid.Column mobile={16} computer={6}>
                    <Form onSubmit={this.form.handleSubmit} loading={this.state.buttonLoader?true:false}>
                    <h1>Register</h1>
                    <Form.Field
                        control={Input}
                        label='Name'
                        name='name'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.name}      
                    />
                    <FFSErrorMessage>{this.state.errors.name ? this.state.errors.name : ""}</FFSErrorMessage>
                    <Form.Field
                        control={Input}
                        label='Email'
                        name='email'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.email} 
                    />
                    <FFSErrorMessage>{this.state.errors.email ? this.state.errors.email : ""}</FFSErrorMessage>

                    <Form.Field
                        control={Input}
                        type='password'
                        label='Password'
                        name='password'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.password} 
                    />
                    <FFSErrorMessage>{this.state.errors.password ? this.state.errors.password : ""}</FFSErrorMessage>

                    <Form.Field
                        control={Input}
                        label='Contact'
                        name='contact'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.contact} 
                    />
                    <FFSErrorMessage>{this.state.errors.contact ? this.state.errors.contact : ""}</FFSErrorMessage>

                    <Button type='submit'  loading={this.state.buttonLoader?true:false}>Register</Button>
                    </Form>
                </Grid.Column>
                <Grid.Column mobile={16} computer={6}></Grid.Column>
                </Grid>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
