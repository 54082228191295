import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { ProtectedRouteUser } from './auth/auth'
import { LastLocationProvider } from 'react-router-last-location';

import 'antd/dist/antd.css'
import 'semantic-ui-css/semantic.min.css'
import './scss/main.scss'

import Body from './includes/Body'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'

import EmailextractIndex from './pages/emailextract/EmailextractIndex'
import EmailextractCreate from './pages/emailextract/EmailextractCreate'
import EmailextractCreate2 from './pages/emailextract/EmailextractCreate2'
import EmailextractBulk from './pages/emailextract/EmailextractBulk'
import EmailextractView from './pages/emailextract/EmailextractView'

import EmailFilter from './pages/EmailFilter'
import BlockMyEmail from './pages/BlockMyEmail'

import ExtractConditions from './pages/emailextract/conditions/ExtractConditions'

import UserCreate from "./pages/users/UserCreate";
import UserIndex from "./pages/users/UserIndex";
import UserView from './pages/users/UserView'
import UserEdit from './pages/users/UserEdit'
import UserChangepassword from './pages/users/UserChangepassword'


import Version from './pages/Version'
import Loginip from './pages/Loginip';


import SendemailAll from "./pages/sendemail/SendemailAll";
import SendemailSMTP from "./pages/sendemail/SendemailSMTP";
import SendmailNew from './pages/sendemail/SendmailNew'
import SendmailView from './pages/sendemail/SendmailView'
import SendemailViewDetails from './pages/sendemail/SendemailViewDetails'


import EmailtempleteAll from './pages/sendemail/templetes/EmailtempleteAll'
import EmailtempleteCreate from './pages/sendemail/templetes/EmailtempleteCreate'
import EmailtempleteView from './pages/sendemail/templetes/EmailtempleteView'
import EmailtempleteEdit from './pages/sendemail/templetes/EmailtempleteEdit'






function App() {
  return (
    <Router>
      <LastLocationProvider>
      <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/emailextract/viewfull/:id' component={SendemailViewDetails} />
      <Route exact path='/blockmyemail/:id' component={BlockMyEmail} />


        <Body>
          <ProtectedRouteUser exact path='/' component={Home} />
          {/* <Route exact path='/login' component={Login} /> */}
          <Route exact path='/register' component={Register} />

          
          <ProtectedRouteUser exact path='/emailextract' component={EmailextractIndex} />
          <ProtectedRouteUser exact path='/emailextract/conditions' component={ExtractConditions} />


          <ProtectedRouteUser exact path='/emailextract/view/:id' component={EmailextractView} />

          <ProtectedRouteUser exact path='/emailextract/new' component={EmailextractCreate} />
          <ProtectedRouteUser exact path='/emailextract/quick' component={EmailextractCreate2} />
          <ProtectedRouteUser exact path='/emailextract/bulk' component={EmailextractBulk} />


          

          <ProtectedRouteUser exact path='/users/create' component={UserCreate} />
          <ProtectedRouteUser exact path='/users' component={UserIndex} />
          <ProtectedRouteUser exact path='/users/view/:id' component={UserView} />
          <ProtectedRouteUser exact path='/users/edit/:id' component={UserEdit} />
          <ProtectedRouteUser exact path='/users/changepassword/:id' component={UserChangepassword} />

          <ProtectedRouteUser exact path='/version' component={Version} />
          
          <ProtectedRouteUser exact path='/loginips' component={Loginip} />


          <ProtectedRouteUser exact path='/allsendemail' component={SendemailAll} />
          <ProtectedRouteUser exact path='/allsendemail/view/:id' component={SendmailView} />


          <ProtectedRouteUser exact path='/emailsmtp' component={SendemailSMTP} />
          <ProtectedRouteUser exact path='/sendnewemail' component={SendmailNew} />

          <ProtectedRouteUser exact path='/emailfilter' component={EmailFilter} />





          
          
          <ProtectedRouteUser exact path='/emailtempletes' component={EmailtempleteAll} />
          <ProtectedRouteUser exact path='/emailtempletes/create' component={EmailtempleteCreate} />
          <ProtectedRouteUser exact path='/emailtempletes/view/:id' component={EmailtempleteView} />
          <ProtectedRouteUser exact path='/emailtempletes/edit/:id' component={EmailtempleteEdit} />


          
          
          

      
        </Body>
      </Switch>
      </LastLocationProvider>
    </Router>
  );
}

export default App;
