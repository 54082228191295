import React, { Component } from 'react'
import { connect } from 'react-redux'
import {check} from '../actions'
import { Breadcrumb,Statistic } from 'antd';
import { Grid } from 'semantic-ui-react'
import Chart from "react-google-charts";
import API from '../api/API'


export class Home extends Component {

  constructor(props){
    super(props)
    this.state={
      total:'',
      data1:'',
      data2:'',
      data3:''
    }
  }
    

    componentDidMount(){

      API.get('/dashboard')
      .then(response=>{
        if(response.data.response){
          this.setState({
            total:response.data.datas
          })
        }
      })

      API.get('/dashboard/admindashboard1')
      .then(response=>{
        // console.log(response.data.data)
        if(response.data.response){
          this.setState({
            data1:response.data.data
          })
        }
      })

      API.get('/dashboard/admindashboard2')
      .then(response=>{
        // console.log(response.data.data)
        if(response.data.response){
          this.setState({
            data2:response.data.data
          })
        }
      })


      API.get('/dashboard/admindashboard3')
      .then(response=>{
        console.log(response.data)
        if(response.data.response){
          this.setState({
            data3:response.data.data
          })
        }
      })

    }

  
    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            
           

            <Grid>

            <Grid.Column mobile={16} tablet={4} computer={4}>
              <center>
              <Statistic title="Total Domain Extracted" value={this.state.total.totdomainextracted} />
              </center>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <center>
              <Statistic title="Total Email Send" value={this.state.total.totemailextracted} />
              </center>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <center>
              <Statistic title="Total User" value={this.state.total.totuser} />
              </center>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <center>
              <Statistic title="Total Active IP" value={this.state.total.totip} />
              </center>
            </Grid.Column>
           


            <Grid.Column mobile={16} tablet={8} computer={8}>

            

              <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.state.data1}
                // data={[
                //   ['User', 'Domain Extracted'],
                //   ['User 1', 101],
                //   ['User 2', 2000],
                //   ['User 3', 200],
                //   ['User 4', 0],
                //   ['User 5', 700],
                // ]}
                options={{
                  title: 'Domain Extracted',
                }}
                rootProps={{ 'data-testid': '1' }}
              />
              
              
            </Grid.Column>


            <Grid.Column mobile={16} tablet={8} computer={8}>

            

              <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.state.data2}
                // data={[
                //   ['User', 'Domain Extracted'],
                //   ['User 1', 101],
                //   ['User 2', 2000],
                //   ['User 3', 200],
                //   ['User 4', 0],
                //   ['User 5', 700],
                // ]}
                options={{
                  title: 'Email Send',
                }}
                rootProps={{ 'data-testid': '1' }}
              />
              
              
            </Grid.Column>


              <Grid.Column mobile={16} tablet={8} computer={16}>
                <Chart
                  width={'100%'}
                  height={'300px'}
                  chartType="AreaChart"
                  loader={<div>Loading Chart</div>}
                  data={this.state.data3}
                  // data={[
                  //   ['Date', 'Email Send', 'Domain Extracted'],
                  //   ['2013', 1000, 400],
                  //   ['2014', 1170, 460],
                  //   ['2015', 660, 1120],
                  //   ['2016', 1030, 540],
                  //   ['2017', 1030, 540],
                  //   ['2018', 1030, 540],
                  //   ['2019', 1030, 540],
                  //   ['2020', 1030, 540],

                  // ]}
                  options={{
                    title: 'Domain extracted & Email Send',
                    // hAxis: { title: 'Date', titleTextStyle: { color: '#333' } },
                    vAxis: { minValue: 0 },
                    // For the legend to fit, we make the chart area smaller
                    chartArea: { width: '70%', height: '70%' },
                    // lineWidth: 25
                  }}
                  // For tests
                  rootProps={{ 'data-testid': '1' }}
                />
              </Grid.Column>



              

                
              {/* <Grid.Column mobile={16} tablet={8} computer={16}>
              <Chart
                width={'100%'}
                height={'500px'}
                chartType="ComboChart"
                loader={<div>Loading Chart</div>}
                data={[
                  [
                    'Month',
                    'Bolivia',
                    'Ecuador',
                    'Madagascar',
                    'Papua New Guinea',
                    'Rwanda',
                    'Average',
                  ],
                  ['2004/05', 6000, 938, 522, 998, 450, 614.6],
                  ['2005/06', 135, 1120, 599, 1268, 288, 900],
                  ['2006/07', 157, 1167, 587, 807, 397, 623],
                  ['2007/08', 139, 1110, 615, 968, 215, 609.4],
                  ['2008/09', 800, 691, 629, 1026, 366, 569.6],
                  
                ]}
                options={{
                  title: 'Monthly Coffee Production by Country',
                  vAxis: { title: 'Cups' },
                  hAxis: { title: 'Month' },
                  seriesType: 'bars',
                  series: { 5: { type: 'line' } },
                }}
                rootProps={{ 'data-testid': '1' }}
              />

              </Grid.Column> */}


              
            </Grid>

            








            {/* <Label as='a' image>
      <img src='/images/avatar/small/joe.jpg' />
      Joe
    </Label>
    <Label as='a' image>
      <img src='/images/avatar/small/elliot.jpg' />
      Elliot
    </Label>
    <Label as='a' image>
      <img src='/images/avatar/small/stevie.jpg' />
      Stevie
    </Label> */}
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})


export default connect(mapStateToProps, {check})(Home)
