import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message } from 'antd';
import { Form,Loader } from 'semantic-ui-react'
import CKEditor from 'ckeditor4-react-advanced';
import API from '../../../api/API'

export class EmailtempleteEdit extends Component {

    constructor(props){
        super(props)
        this.state={
            formLoading:false,
            data:false

        }
        this.handleTextChange=this.handleTextChange.bind(this)
        this.onEditorChange=this.onEditorChange.bind(this)
    }


    componentDidMount(){
        API.get(`/emailtemplete/view/${this.props.match.params.id}`)
        .then(response=>{
            this.setState({
                data:true,
                _id:response.data.data._id,
                name:response.data.data.name,
                subject:response.data.data.subject,
                body:response.data.data.body,
            })
        })
    }

    handleTextChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }


    onEditorChange( evt ) {
        this.setState({
            body: evt.editor.getData()
        });
    }


    handleSubmit=e=>{
        this.setState({formLoading:true})
        API.post('/emailtemplete/emailtempleteupdate',this.state)
        .then(response=>{
            console.log(response.data)
            if(response.data.response){
                this.setState({formLoading:false})
                message.success('Success');
                this.props.history.push('/emailtempletes');
            }else{
                this.setState({formLoading:false})
                message.success('Success');
            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Edit Email Templetes</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {this.state.data===false
            ?<Loader active inline='centered' />
            :
            <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                <Form.Input fluid label='Name' placeholder='Templete name' onChange={this.handleTextChange}
                        name='name'
                        value={this.state.name} required />
                <Form.TextArea label='Subject' placeholder='Templete subject' onChange={this.handleTextChange}
                        name='subject'
                        value={this.state.subject} required /> 
                <div className="required field">
                    <label>Body</label>
                    <CKEditor
                        onChange={this.onEditorChange}
                        config={{
                            width: '100%',
                            height: '150px',
                        }}
                        data={this.state.body}
                    />
                </div>
                <Form.Button>Update</Form.Button>
                </Form>
            }
                

            </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailtempleteEdit)
