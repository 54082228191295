import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Table} from 'semantic-ui-react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as EmailValidator from 'email-validator';

export class TableExtractCreate extends Component {

    constructor(props){
        super(props)
        this.state={
            datas:null
        }
    }

    componentDidMount(){
        // console.log(this.props.datas)
    }

    render() {
        return (
            <>
            {this.props.datas===null
            ?
            <center></center>
            :
            <>
            {/* {this.props.showExcelBtn
            ?
            <>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="ui button mycb1"
                table="table-to-xls"
                filename={this.props.uuid}
                sheet="tablexls"
                buttonText="Download as XLS"
            />
            </>
            
            :<></>} */}
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="ui button mycb1"
                table="table-to-xls"
                filename={this.props.uuid}
                sheet="tablexls"
                buttonText="Download as XLS"
            />
            
            <br />
                
            <Table singleLine id="table-to-xls">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Domains</Table.HeaderCell>
                        <Table.HeaderCell>Crawl status</Table.HeaderCell>
                        <Table.HeaderCell>Email Status</Table.HeaderCell>
                        <Table.HeaderCell>Emails</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.datas.map((data,key)=>{
                    return(
                        <Table.Row>
                        <Table.Cell>{data.domain}</Table.Cell>
                        <Table.Cell>{data.crawlstatus?<span style={{color:'green'}}>Crawled</span>:<span style={{color:'red'}}>{data.status}</span>}</Table.Cell>
                        <Table.Cell> 
                        {data.crawlstatus?
                        <>
                            {!data.emails?<span style={{color:'red'}}>Not Found</span>:<>{data.emails===null?<span style={{color:'red'}}>Not Found</span>:<>{data.emails.length>0 ?<>Found</>:<span style={{color:'red'}}>Not Found</span>}  </>}</>}  
                        </>
                        :
                        <>
                        </>
                        }
                        </Table.Cell>
                        <Table.Cell>
                            {!data.emails
                            ?<></>
                            :
                            <>
                            {data.emails===null?<></>:<> {data.emails.map((ml,key)=>{
                                
                                
                                return(
                                    <>
                                    {EmailValidator.validate(ml)
                                    ?
                                    <>
                                    <span className="ddx1x">{ml},</span>
                                    </>
                                    :
                                    <>
                                    </>
                                    }
                                    </>
                                )
                            })} </>}
                            </>}
                        </Table.Cell>
                        </Table.Row>
                    )
                    })}
                </Table.Body>
            </Table>




            </>
            }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(TableExtractCreate)
