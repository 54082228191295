import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid,Form,Input,Button} from 'semantic-ui-react'
import { message } from 'antd';
import ReactFormInputValidation from "react-form-input-validation";
import {FFSErrorMessage} from '../styles/Styles'
import API from '../api/API'
import cookie from 'react-cookies'
import { withLastLocation } from 'react-router-last-location';


export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
          buttonLoader:false,
          fields: {
            email: "",
            password: "",
          },
          errors: {}
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            email: "required|email",
            password: "required|min:8|max:40"

        });
        this.form.onformsubmit = (fields) => {
          this.setState({buttonLoader:true})

          API.post('/user/userlogin',fields)
            .then(response=>{
            //   console.log(response.data)

              if(response.data.response){

                if(response.data.message==='login_success'){

                    if(response.data.data.usertype==='Admin'){
                        message.success('Login Success');
    
                        cookie.remove('qtonixproject_admin_userdata', { path: '/' })
                        cookie.remove('qtonixproject_admin_userlogin', { path: '/' })
                        cookie.remove('qtonixproject_admin_userid', { path: '/' })
                        // cookie.remove('qtonixproject_admin_useremailverify', { path: '/' })
            
            
                        var expires = new Date();
                        expires.setSeconds(21600);
                        cookie.save('qtonixproject_admin_userdata', response.data.data, { path: '/',expires });
                        cookie.save('qtonixproject_admin_userid', response.data.data._id, { path: '/',expires });
                        // cookie.save('qtonixproject_admin_useremailverify', response.data.data.email_verification, { path: '/',expires });
                        cookie.save('qtonixproject_admin_userlogin',true, { path: '/',expires })
        
        
                        if(this.props.lastLocation===null){
                        this.props.history.push('/');
                        }else{
                        this.props.history.push(this.props.lastLocation.pathname)
                        }
                    }else{
                        message.error('Please login as Admin');
                        this.setState({buttonLoader:false})
                    }


                    
                }
                if(response.data.message==='wrong_password'){
                    message.error('Password is not matching');
                    this.setState({buttonLoader:false})
                }
                if(response.data.message==='wrong_email'){
                    message.error('Wrong Email');
                    this.setState({buttonLoader:false})
                }
              }else{
                message.error('Failed');
                    this.setState({buttonLoader:false})
              }
            })

        }
      }

    render() {
        return (
            <>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}> */}
            <br /><br />
            <br /><br />
            <Grid style={{ padding: 24 }}>
                <Grid.Column mobile={16} computer={5}></Grid.Column>
                <Grid.Column mobile={16} computer={6}>
                    <Form onSubmit={this.form.handleSubmit} loading={this.state.buttonLoader?true:false}>
                    <h1>Login</h1>
                    
                    <Form.Field
                        control={Input}
                        label='Email'
                        name='email'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.email} 
                    />
                    <FFSErrorMessage>{this.state.errors.email ? this.state.errors.email : ""}</FFSErrorMessage>

                    <Form.Field
                        control={Input}
                        type='password'
                        label='Password'
                        name='password'
                        disabled={this.state.buttonLoader?true:false}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.password} 
                    />
                    <FFSErrorMessage>{this.state.errors.password ? this.state.errors.password : ""}</FFSErrorMessage>

                    

                    <Button type='submit'  loading={this.state.buttonLoader?true:false}>Login</Button>
                    </Form>
                </Grid.Column>
                <Grid.Column mobile={16} computer={6}></Grid.Column>
                </Grid>
            {/* </div> */}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

const LoginwithLastLocation = withLastLocation(Login);
export default connect(mapStateToProps, mapDispatchToProps)(LoginwithLastLocation)
