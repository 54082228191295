import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Grid,Form,Input,Button,Loader,Card} from 'semantic-ui-react'
import { Breadcrumb,message } from 'antd';

import ReactFormInputValidation from "react-form-input-validation";
import {FFSErrorMessage} from '../../styles/Styles'
import API from '../../api/API'

export class UserEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
          buttonLoader:false,
          fromemails:null,

          pageLoading:true,
          fields: {
            email: "",
            // password: "",
            usertype:'User'
          },
          errors: {}
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            email: "required|email",
            // password: "required|min:8|max:40",
            name: "required|min:2|max:90",
            contact: "required|numeric|digits_between:10,12",
            usertype: "required",
            domainextractlimit: "required|numeric",
            assignemailaccount: "required|email",
            dailyemailsendlimit: "required|numeric",

        });
        this.form.onformsubmit = (fields) => {

          this.setState({buttonLoader:true})

          API.post('/user/update',fields)
          .then(response=>{
              console.log(response.data)
              if(response.data.response){
                this.setState({buttonLoader:false})
                message.success('Success');
                this.props.history.push('/users')
              }else{
                message.error('Try again');
                this.setState({buttonLoader:false})
              }
          })

        }
    }


    // componentDidMount(){
    //     API.get('/emailsmtp')
    //     .then(response=>{
    //         this.setState({
    //             fromemails:response.data.data
    //         })
    //     })
    // }

    componentDidMount(){
        // alert(this.props.match.params.id)
        API.get(`/user/viewuserdetails/${this.props.match.params.id}`)
        .then(response=>{
            console.log(response.data)
            if(response.data.response){
                this.setState({
                    pageLoading:false,
                    fields:{
                    _id:response.data.data._id,
                    name:response.data.data.name,
                    email:response.data.data.email,
                    contact:response.data.data.contact,
                    usertype:response.data.data.usertype,

                    dailyemailsendlimit:response.data.data.dailyemailsendlimit,
                    assignemailaccount:response.data.data.assignemailaccount,
                    domainextractlimit:response.data.data.domainextractlimit,

                    }
                    
                })
            }else{
                this.props.history.push('/users')
            }
        })


        API.get('/emailsmtp')
        .then(response=>{
            this.setState({
                fromemails:response.data.data
            })
        })
    }


    render() {
        return (
            <>
               <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Users</Breadcrumb.Item>
                <Breadcrumb.Item>Edit</Breadcrumb.Item>


            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {this.state.pageLoading
            ?<Loader active inline='centered' />
            :
            <>
            <Grid>
                <Grid.Column mobile={16} computer={3}></Grid.Column>
                <Grid.Column mobile={16} computer={10}>
                    <Form onSubmit={this.form.handleSubmit} loading={this.state.buttonLoader?true:false}>







                    <Card style={{width:'100%'}}>
                        <Card.Content>
                            <Card.Header>Personal Information</Card.Header>
                            <Card.Description style={{marginTop:'30px'}}>
                            
                                <Form.Field
                                    control={Input}
                                    label='Name'
                                    name='name'
                                    disabled={this.state.buttonLoader?true:false}
                                    onBlur={this.form.handleBlurEvent}
                                    onChange={this.form.handleChangeEvent}
                                    value={this.state.fields.name}      
                                />
                                <FFSErrorMessage>{this.state.errors.name ? this.state.errors.name : ""}</FFSErrorMessage>
                                
                                
                                <Form.Field
                                    control={Input}
                                    label='Email'
                                    name='email'
                                    // disabled={this.state.buttonLoader?true:false}
                                    // onBlur={this.form.handleBlurEvent}
                                    // onChange={this.form.handleChangeEvent}
                                    value={this.state.fields.email} 
                                    
                                />
                                <FFSErrorMessage>{this.state.errors.email ? this.state.errors.email : ""}</FFSErrorMessage>

                                
                                <Form.Field
                                    control={Input}
                                    label='Contact'
                                    name='contact'
                                    disabled={this.state.buttonLoader?true:false}
                                    onBlur={this.form.handleBlurEvent}
                                    onChange={this.form.handleChangeEvent}
                                    value={this.state.fields.contact} 
                                />
                                <FFSErrorMessage>{this.state.errors.contact ? this.state.errors.contact : ""}</FFSErrorMessage>



                                <Form.Group inline>
                                <label>UserType</label>
                                    <Form.Field
                                        label='User'
                                        control='input'
                                        type='radio'
                                        name='usertype'
                                        disabled={this.state.buttonLoader?true:false}
                                        onBlur={this.form.handleBlurEvent}
                                        onChange={this.form.handleChangeEvent}
                                        value='User'
                                        checked={this.state.fields.usertype==='User'}
                                    />
                                    <Form.Field
                                        label='Admin'
                                        control='input'
                                        type='radio'
                                        name='usertype'
                                        disabled={this.state.buttonLoader?true:false}
                                        onBlur={this.form.handleBlurEvent}
                                        onChange={this.form.handleChangeEvent}
                                        value='Admin'
                                        checked={this.state.fields.usertype==='Admin'}

                                    />
                                </Form.Group>


                            
                            </Card.Description>
                        </Card.Content>
                    </Card>

                    <Card style={{width:'100%'}}>
                        <Card.Content>
                            <Card.Header>Account Limits</Card.Header>
                            <Card.Description style={{marginTop:'30px'}}>

                                <Form.Field
                                    control={Input}
                                    label='Daily domain extract limit'
                                    name='domainextractlimit'
                                    disabled={this.state.buttonLoader?true:false}
                                    onBlur={this.form.handleBlurEvent}
                                    onChange={this.form.handleChangeEvent}
                                    value={this.state.fields.domainextractlimit}      
                                />
                                <FFSErrorMessage>{this.state.errors.domainextractlimit ? this.state.errors.domainextractlimit : ""}</FFSErrorMessage>
                                

                                {/* <Form.Field
                                    control={Input}
                                    label='Assign email account'
                                    name='assignemailaccount'
                                    disabled={this.state.buttonLoader?true:false}
                                    onBlur={this.form.handleBlurEvent}
                                    onChange={this.form.handleChangeEvent}
                                    value={this.state.fields.assignemailaccount}      
                                />
                                <FFSErrorMessage>{this.state.errors.assignemailaccount ? this.state.errors.assignemailaccount : ""}</FFSErrorMessage>
                                 */}

                                <Form.Field 
                                     control='select'
                                     label='Assign email account'
                                     name='assignemailaccount'
                                     disabled={this.state.buttonLoader?true:false}
                                     onBlur={this.form.handleBlurEvent}
                                     onChange={this.form.handleChangeEvent}
                                     value={this.state.fields.assignemailaccount} 
                                >
                                    {this.state.fromemails===null
                                    ?
                                    <option value="">loading...</option>
                                    :
                                    <>
                                        {this.state.fromemails.map((fe)=>{
                                            return(
                                                <option value={fe.email} key={fe._id}>{fe.email}</option>
                                            )
                                        })}
                                    </>
                                    }
                                </Form.Field>
                                <FFSErrorMessage>{this.state.errors.assignemailaccount ? this.state.errors.assignemailaccount : ""}</FFSErrorMessage>



                                <Form.Field
                                    control={Input}
                                    label='Daily email send limit'
                                    name='dailyemailsendlimit'
                                    disabled={this.state.buttonLoader?true:false}
                                    onBlur={this.form.handleBlurEvent}
                                    onChange={this.form.handleChangeEvent}
                                    value={this.state.fields.dailyemailsendlimit}      
                                />
                                <FFSErrorMessage>{this.state.errors.dailyemailsendlimit ? this.state.errors.dailyemailsendlimit : ""}</FFSErrorMessage>
                                
                                <br />

                                <Button type='submit'  floated='right'  loading={this.state.buttonLoader?true:false}>Update</Button>

                            </Card.Description>
                        </Card.Content>
                    </Card>





                    </Form>
                </Grid.Column>
                <Grid.Column mobile={16} computer={3}></Grid.Column>
                </Grid>
            </>
            }
                

                </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)
