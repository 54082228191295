import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message,Popconfirm } from 'antd';
import { Form, Input, Button, Table,Loader } from 'semantic-ui-react'
import API from '../../api/API'
import Moment from 'react-moment';

export class SendemailSMTP extends Component {


    constructor(props){
        super(props)
        this.state={
            datas:null,
            formLoading:false,
            ip:''
        }
        this.handleChange=this.handleChange.bind(this);
    }

    componentDidMount(){
        this.getData();
    }


    getData=e=>{
        API.get('/emailsmtp')
        .then(response=>{
            console.log(response)
            this.setState({
                datas:response.data.data
            })
        })
    }

    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit=e=>{
        this.setState({formLoading:true})
        API.post('/emailsmtp',this.state)
        .then(response=>{
            if(response.data.response){
                message.success('Added');
                this.setState({formLoading:false,email:''})
                this.getData();
            }else{
                message.warning('Failed');
            }
        })
        
    }

    handleDelete=e=>{
        API.get(`/emailsmtp/delete/${e}`)
        .then(response=>{
            if(response.data.response){
                message.success('Success');
                this.getData();
            }else{
                message.warning('Failed');

            }
        })
    }

    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Email SMTP</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Form onSubmit={this.handleSubmit} loading={this.state.formLoading}>
                    <Form.Field
                        control={Input}
                        label='Email'
                        placeholder='Enter email'
                        required
                        onChange={this.handleChange}
                        name='email'
                        value={this.state.email}
                    />
                    <Form.Field
                        control={Input}
                        label='Name'
                        placeholder='Enter name'
                        required
                        onChange={this.handleChange}
                        name='name'
                        value={this.state.name}
                    />
                    <Form.Field control={Button}>Add</Form.Field>
                </Form>
                <br />
                {this.state.datas===null
                    ?<Loader active inline='centered' />
                    :
                    <Table singleLine>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.datas.map((data)=>{
                                return(

                                        <Table.Row key={data._id}>
                                        <Table.Cell><b>{data.email}</b></Table.Cell>
                                        <Table.Cell><b>{data.name}</b></Table.Cell>
                                        <Table.Cell><Moment format="YYYY-MM-DD dddd  HH:MM:ss">{data.createdAt}</Moment></Table.Cell>

                                        <Table.Cell textAlign='right'>
                                        <Popconfirm
                                            title="Are you sure to delete this Email?"
                                            onConfirm={()=>this.handleDelete(data._id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button content='Delete' size='mini' color='red' />
                                        </Popconfirm>
                                           
                
                                        </Table.Cell>
                                    </Table.Row>

                                )
                            })}
                            

                        </Table.Body>
                    </Table>
                }
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SendemailSMTP)
