import React, { Component } from 'react'
import { Breadcrumb } from 'antd';


export default class Version extends Component {

    

    render() {
        return (
            <>
              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Version</Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            
            <section className="version">
                <h5>Version 1.01</h5>
                <p>- Fixed duplicate domain entry</p>
                <p>- Improved performance</p>
            </section>
            <br /><br />
            <section className="version">
                <h5>Version 1.02</h5>
                <p>- Domain import using CSV</p>
				<p>- IP based login</p>
            </section>
            <br /><br />
            <section className="version">
                <h5>Version 1.03</h5>
                <p>- Added delete button in all email extract page</p>
                <p>- Admin can add, remove SMTP E-mail</p>
				{/* 
                <p>- email testing</p> 
                <p>- email dynamic templete building backend</p>
                <p>- email dynamic smtp setting in frontend</p>
                */}
            </section>


            </div>  
            </>
        )
    }
}
