import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb,message } from 'antd';
import {Form,Button} from 'semantic-ui-react'
import API from '../../api/API'
import { v4 as uuidv4 } from 'uuid';
import cookie from 'react-cookies'
import TableExtractCreate from './TableExtractCreate'


export class EmailextractCreate extends Component {

    constructor(props){
        super(props)
        this.state={
          extractBtnShowHide:false,
          extractNowBtnShowHide:false,
          domainCreateno:false,
          userid:cookie.load('qtonixproject_admin_userid'),
          uuid:null,
          domains:'',
          searchtype:'deep',
          data:null,
          domainLength:0,
          totalDomainExtract:0,
          showExcelBtn:false
        }
        this.handleChange=this.handleChange.bind(this)
      }

      componentDidMount(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm +  dd + yyyy;
        this.setState({
          uuid:today+uuidv4()
        })
      }
    

      handleChange(e){
        this.setState({
          [e.target.name]:e.target.value
        })
      }

      getData = () => {
        API.get(`/email/indexuseruuid/${this.state.userid}/${this.state.uuid}`)
            .then(response=>{
                this.setState({
                    data:response.data.data
            })
        })
      }

      //============ADD HTTPS DOMAIN============//
      handleSubmit=()=>{

        const word = this.state.domains;
        const domains = word.split("\n");

        const state= this.state;

        var domainCreate = [];

        domains.forEach(domainFunction);
        function domainFunction(domainurl, index) {
            // console.log(domainurl+'-'+index)
            const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
            const myUrl = withHttp(domainurl);

            var data={
                domain:myUrl,
                uuid:state.uuid,
                userid:state.userid
            }

            domainCreate.push(data);

        }
        this.setState({
            extractBtnShowHide:false,
            extractNowBtnShowHide:true,
            domainCreate:domainCreate,
            domainCreateno:0,
            domainLength:domainCreate.length
        })

        message.success('Uplaod done');
        
      }
      //============ADD HTTPS DOMAIN============//

      showDomains=()=>{
        this.getEmailFromDomain();
      }

      //============GET EMAILS============//
      getEmailFromDomain(){

        var number =  this.state.domainCreateno;
        this.setState({
          extractNowBtnShowHide:true
        })


        if(number===this.state.domainCreate.length){
            this.setState({
              showExcelBtn:true
            })
            message.success('Completed');
            
        }else{
            // message.loading({ content: `fetching ${this.state.domainCreate[number].domain} ...` })
            var data={
                domain:this.state.domainCreate[number].domain,
                userid:this.state.domainCreate[number].userid,
                uuid:this.state.domainCreate[number].uuid,
                domainCreateno:number
            };
            API.post(`/email/getemailbyurl`,data)
            .then(response=>{
                if(response.data.response){
                    this.getEmailFromDomain();
                    this.setState({
                        domainCreateno:response.data.domainCreateno,
                        data:response.data.data,
                        totalDomainExtract:response.data.data.length
                    })
                    // _.filter(a, function(o) { if (o.a < 4) return o }).length;
                    // console.log(response.data.data)
                    // console.log(_.filter(response.data.data, function(o) { if (o.emails.length === 0) return o }).length)
                }else{

                }
            })   
        }
      }
      //============GET EMAILS============//

      

    render() {
      console.log(this.state.data);
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>All Email Extract</Breadcrumb.Item>
                <Breadcrumb.Item>New Email Extract</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
               
                {this.state.extractBtnShowHide
                ?
                <>
                  
                </>
                :
                <>

                <Form  onSubmit={this.handleSubmit}>
                    <Form.TextArea
                        label='Domains'
                        name='domains'
                        onChange={this.handleChange}
                        value={this.state.domains}
                        placeholder=' abc.com
                        qwe.com
                        zyz.com ...'
                        disabled={this.state.extractNowBtnShowHide}
                    />
                    <Button type='submit' disabled={this.state.extractNowBtnShowHide}>Upload</Button>

                    &nbsp;
                    {this.state.extractNowBtnShowHide
                    ?<Button type="button" onClick={()=>this.showDomains()}>Extract Now</Button>
                    :<></>}
                </Form>
                <br />
                
                </>
                }
                
    
                {this.state.data===null
                ?
                <>
                </>
                :
                <>
                <h5>Extract ID: {this.state.uuid}</h5>
                <h5 style={{marginTop:'-10px'}}>Total Domains: {this.state.domainLength}</h5>
                <h5 style={{marginTop:'-10px'}}>Total Extracted: {this.state.totalDomainExtract}</h5>

                {this.state.domainCreateno===this.state.domainCreate.length
                ?
                <></>
                :
                <h5>fetching domain {this.state.domainCreate[this.state.domainCreateno].domain} ...</h5>
                
                }
                
                
                <br />
                <TableExtractCreate datas={this.state.data} uuid={this.state.uuid} showExcelBtn={this.state.showExcelBtn}/>

                </>
                }
                
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailextractCreate)
