import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from 'antd';
import { Grid } from 'semantic-ui-react';
import BlockDomain from './BlockDomain'


export class ExtractConditions extends Component {
    render() {
        return (
            <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Extract Conditions</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                {/* <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur at tempore vitae natus excepturi sint assumenda nemo, doloribus, enim ipsum, recusandae libero quibusdam mollitia impedit id qui adipisci placeat corporis?</p>
                    </Grid.Column>
                </Grid> */}

                <Grid stackable columns={1}>
                    <Grid.Row>
                    <Grid.Column>
                        <BlockDomain />
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtractConditions)
